<template>
  <div>
    <section v-if="!currentEmailTemplate">
      <div class="wrap">
        <div class="container-sm tile">
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <h3>No current email template created.</h3>
              <p>Create one now!</p>
              <p><button class="button primary" @click="newTemplate">Create a New template <img src="../assets/icon-arrow-next.svg" alt=""></button></p>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section v-if="currentEmailTemplate">
      <div class="wrap">
        <div class="container-sm tile email-body">
          <div class="row">
            <div class="col-10">
              <img src="../assets/window-min-dots.svg" alt="" style="margin-bottom: 1em;">
            </div>
          </div>
          <div class="row">
            <hr>
          </div>
          <div class="row">
            <div class="col-2"><p>FROM:</p></div>
            <div class="col-10"><p>{{currentEmail}}</p></div>
          </div>
          <div class="row">
            <hr>
          </div>
          <div class="row">
            <div class="col-2"><p>SUBJECT:</p></div>
            <div class="col-10"><p>OOOh-no</p></div>
          </div>
          <div class="row">
            <hr>
          </div>
          <div class="row">
            <div class="col-2"><p>BODY:</p></div>
            <div class="col-8">
              <div v-html="currentEmailTemplate">
              </div>

              <p>PS: Did this email make you smile? It was created using Gong’s Out of Office Email Generator. <a href="https://www.outofofficetemplates.com/">Create your version here.</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mt-4" v-if="currentEmailTemplate">
      <div class="container-sm">
        <div class="row">
          <div class="col-12 col-md-8">
            <p><strong>P.S.</strong> I also emailed you this template.</p>

            <p><strong>P.P.S.</strong> Be sure to double-check your OOO template for typos. No auto-generator is perfect.</p>

            <p><strong>P.P.P.S.</strong> Want free sales insights? <a href="https://www.linkedin.com/company/gong-io" target="_blank">Follow Gong on LinkedIn</a></p>
          </div>
          <div class="col-12 col-md-4 nav-items text-end">
            <button class="button primary" @click="newTemplate">Create a New template <img src="../assets/icon-arrow-next.svg" alt=""></button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
  props: {

  },

  data(){
    return{
      publicPath: process.env.BASE_URL,
    }
  },

  computed: {
    currentQuestion() {
      return this.$store.getters.currentQuestion;
    },
    currentMeme() {
      return this.$store.getters.currentMeme;
    },
    currentMemeImage() {
      return 'meme-' + this.$store.getters.currentMeme + '.png';
    },
    currentDestination() {
      return this.$store.getters.currentDestination;
    },
    currentActivity() {
      return this.$store.getters.currentActivity;
    },
    currentContact() {
      return this.$store.getters.currentContact;
    },
    currentContactName() {
      return this.$store.getters.currentContactName;
    },
    currentContactInfo() {
      return this.$store.getters.currentContactInfo;
    },
    currentStartDate() {
      return this.$store.getters.currentStartDate;
    },
    currentEndDate() {
      return this.$store.getters.currentEndDate;
    },
    currentEmail() {
      return this.$store.getters.currentEmail;
    },
    currentEmailTemplate() {
      return this.$store.getters.currentEmailTemplate;
    },
  },

  methods:{
    getImgUrl(pic) {
      return require('../assets/'+pic)
    },

    newTemplate(){
      this.$store.commit('resetStore');
      this.$router.push({ path: '/' });
    }
  }
};
</script>

<style scoped lang="scss">

.container{
  max-width: 1240px;
}

.wrap{
  margin-top: -4em;
  @media (max-width: 1060px) {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.container-sm{
  max-width: 1040px;

  .col-2{
    width: 10%;

    @media (max-width: 768px) {
      width: 16.6666666667%;
    }
  }

  hr{
    border: none;
    height: 1px;
    background-color: #CECECE;
  }
}

.meme-image{
  box-shadow: 0px 0px 28px rgba(50, 21, 89, 0.16);
  border-radius: 20px;
}

.tile{
  padding: 4em 0;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 28px rgba(50, 21, 89, 0.08);
  border: 1px solid #D9D9D9;

  &.email-body{
    padding: 1em 12px;
  }
}

.nav-items{
  padding-top: 1em;

  > button:first-child{
    margin-right: 20px;
  }
}

.button{
  color: #321559;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.2em;
  font-weight: 700;
  border: transparent;
  height: 32px;
  padding: 10px 18px 8px;
  border-radius: 36px;
  display: inline-flex;
  justify-content: center; /* center the content horizontally */
  align-items: center; /* center the content vertically */
  position: relative;

  &.primary{
    background-color: #F7CD05;
    //padding-right: 30px;

    img{
      margin-left: 0.5em;
    }


    &:hover{
      background-color: #ac9416;
    }
  }

  &.secondary{
    background-color: transparent;
    padding-left: 30px;

    img{
      margin-right: 0.5em;
    }

    &:hover{
      background-color: #c8c5ce;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 2em;
    line-height: 17px;
  }
}

</style>