<template>
  <div class="home">
    <Hero :h1=HeroH1 :h2=HeroH2 />
    <ThankYou />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import ThankYou from "@/components/ThankYou.vue";
import Footer from "@/components/Footer.vue";


export default {
  name: "Home",

  components: {
    Hero,
    ThankYou,
    Footer,
  },


  data() {
    return {
      HeroH1: this.$store.state.HeroH1,
      HeroH2: this.$store.state.HeroH2,
      meme: '',
    };
  },

  methods: {},
};
</script>
